import { useEffect, useRef } from "react";

export function useInactivityTimeout(onTimeout, timeout = 15 * 60 * 1000) {
  const timeoutId = useRef(null);

  const resetTimer = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(onTimeout, timeout);
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll", "click"];

    const handleActivity = () => resetTimer();

    for (let event of events) {
      window.addEventListener(event, handleActivity);
    }

    resetTimer();

    return () => {
      for (let event of events) {
        window.removeEventListener(event, handleActivity);
      }
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [onTimeout, timeout]);
}
