import './NewEditHoldingTemplate.css'
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { AddAlert, AlertType, Input } from '../../../../../../../../component';
import NewButton, { ButtonModel } from '../../../../../../../../newcomponents/button/button';
import { loaded, loading } from '../../../../../../../../layout/redux/AppActions';
import { putSaveTemplateType } from '../../../../../../http';
import NewMultiSelect from '../../../../../../../../newcomponents/form/select/multiselect';
import NewSelect from '../../../../../../../../newcomponents/form/select/select';
import { uniqueIDs } from '../../../../../../../../utils/uniqueIDs';
import { getSpecialties } from '../../../../../../../agenda/SelectScheduleHealthPlaceUserManager/http';
import Modal, { MODAL_INIT_PROPS } from '../../../../../../../../newcomponents/modal/modal';
import DeleteHoldingTemplate from '../DeleteHoldingTemplate/DeleteHoldingTemplate';
import { useApp } from '../../../../../../../../layout/App';

export const ACTIVE_FIELD_OPTIONS = [
    { id: 'main_complaint', name: 'Queixa Principal', component: 'text' },
    { id: 'history_of_current_illness', name: 'História da doença atual (HDA)', component: 'text'  },
    { id: 'past_pathological_history', name: 'História patológica pregressa (HPP)', component: 'text' },
    { id: 'physical_exam', name: 'Exame Físico', component: 'text' },
    { id: 'diagnosis', name: 'Hipóteses diagnósticas', component: 'text' },
    { id: 'conduct', name: 'Conduta', component: 'text' },
    { id: 'reason_for_consultation', name: 'Motivo de consulta (CIAP-2)', component: 'select' },
    { id: 'intervention_clinical_procedures', name: 'Intervenção e/ou procedimentos clínicos (CIAP-2)', component: 'select' },
    { id: 'related_diseases_and_problems', name: 'Doenças e Problemas Relacionados (CID-10)', component: 'select' },
    { id: 'interrogatory_about_various_devices', name: 'Interrogatório sobre diversos aparelhos (IDA)', component: 'text' },
    { id: 'physiological_history', name: 'História fisiológica', component: 'text' },
    { id: 'vaccination_history', name: 'História vacinal', component: 'text' },
    { id: 'assessment_by_specialists_and_examinations_carried_out', name: 'Avaliação por especialistas e exames realizados', component: 'text' },
    { id: 'family_history', name: 'História Patológica Familiar', component: 'text' },
    { id: 'lifestyle_habits', name: 'Hábitos de vida', component: 'text' },
    { id: 'social_history', name: 'História social', component: 'text' },
    { id: 'impression', name: 'Impressão', component: 'text' },
    { id: 'nursing_planning', name: 'Planejamento de enfermagem', component: 'text' },
    { id: 'nursing_planning_evolution', name: 'Evolução do planejamento de enfermagem', component: 'text' },
    { id: 'evolution', name: 'Evolução', component: 'text' },
    { id: 'pertinent_observations', name: 'Observações pertinentes', component: 'text' },
    { id: 'medications_for_continuous_use', name: 'Medicamentos de uso contínuo', component: 'text' },
    { id: 'neuropsychomotor_development', name: 'Desenvolvimento Neuropsicomotor', component: 'text' },
  ]

// (NEW) 1. Reusable component for any "subjective/objective/assessment/plan" section
function FieldsFor({ loadKey, holdingTemplate, setHoldingTemplate, toggleOption, moveItem, ACTIVE_FIELD_OPTIONS }) {
    const selectedFields = holdingTemplate[loadKey] || [];
  
    return (
      <div className='NewEditHoldingTemplate-Body'>
        <div className='NewEditHoldingTemplate-Select'>
          <strong>Campos habilitados</strong>
          <NewMultiSelect
            id={loadKey}
            options={ACTIVE_FIELD_OPTIONS}
            selectedOptions={selectedFields.map(({ id }) => id)}
            onSelect={({ optionRef }) => {
              toggleOption(loadKey, ACTIVE_FIELD_OPTIONS, 'id', optionRef)
            }}
            onlyDefaultText
          />
          <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow'>
            {selectedFields.map((field, index) => (
              <div key={field.id} className="NewEditHoldingTemplate-Select-SelectedOptionsRow-Item">
                <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow-Name'>
                  {`${index + 1}º - ${field.name}`}
                </div>
                <div
                  className='NewEditHoldingTemplate-Select-SelectedOptionsRow-CloseBox'
                  onClick={() => toggleOption(loadKey, ACTIVE_FIELD_OPTIONS, 'id', field.id)}
                >
                  x
                </div>
                <div className='NewEditHoldingTemplate-Select-SelectedOptionsRow-Arrows'>
                  <button
                    disabled={index === 0}
                    onClick={() => {
                      const reordered = moveItem(selectedFields, index, index - 1);
                      setHoldingTemplate(prev => ({ 
                        ...prev, 
                        [loadKey]: reordered 
                      }));
                    }}
                  >
                    ↑
                  </button>
                  <button
                    disabled={index === selectedFields.length - 1}
                    onClick={() => {
                      const reordered = moveItem(selectedFields, index, index + 1);
                      setHoldingTemplate(prev => ({ 
                        ...prev, 
                        [loadKey]: reordered 
                      }));
                    }}
                  >
                    ↓
                  </button>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    )
}

function moveItem(array, fromIndex, toIndex) {
    const newArray = [...array];
    const item = newArray.splice(fromIndex, 1)[0];
    newArray.splice(toIndex, 0, item);
    return newArray;
}

const strpTemplate = (holdingTemplate) => {
  return {
    "id": holdingTemplate?.id || null,
    "name": holdingTemplate?.name || '',
    "specialty": holdingTemplate?.specialty || '',
    "health_place": holdingTemplate?.health_place,
    "subjective": holdingTemplate?.subjective || [],
    "objective": holdingTemplate?.objective || [],
    "assessment": holdingTemplate?.assessment || [],
    "plan": holdingTemplate?.plan || [],
  }
}

const parseTemplate = (holdingTemplate) => {
  return {
    "id": holdingTemplate?.id || null,
    "name": holdingTemplate?.name || '',
    "specialty_id": holdingTemplate?.specialty?.id || null,
    "health_place": holdingTemplate?.health_place,
    "subjective": holdingTemplate?.subjective || [],
    "objective": holdingTemplate?.objective || [],
    "assessment": holdingTemplate?.assessment || [],
    "plan": holdingTemplate?.plan || [],
    "can_edit": holdingTemplate?.can_edit || true,
  }
}

export default function NewEditHoldingTemplate({ initHoldingTemplate={}, extraAction=async () => null }) {
    const dispatch = useDispatch()
    const searchTimeout = useRef(null)
    const { currentHealthPlaceUser } = useApp()
    const [holdingTemplate, setHoldingTemplate] = useState(strpTemplate(initHoldingTemplate))
    const [specialtiesOptions, setSpecialtiesOptions] = useState([])
    const [modalInfoV3, setModalInfoV3] = useState(MODAL_INIT_PROPS)
    const [openSection, setOpenSection] = useState('subjective')

    useEffect(() => {
        fetchSpecialtiesOptions({ offset: 0 })
    }, [])

    const fetchSpecialtiesOptions = async (params={}) => {
        params = { ...params, limit: 300 }

        if (['DOCTOR', 'DENTIST', 'PSYCHOLOGIST', 'NUTRITIONIST'].includes(currentHealthPlaceUser?.profile)) {
          params['profile'] = currentHealthPlaceUser?.profile
        }

        try {
            let res = await getSpecialties(params)
            setSpecialtiesOptions(res.data.results)
        } catch(err) {
            console.error('fetchSpecialtiesOptions ~ ', err)
        }
    }

    // Reusable function to toggle a field in subjective/objective/assessment/plan
    const toggleOption = (loadKey, optionsList, fieldNameRef, optionRef) => {
		const selectedOption = optionsList.find(option => option[fieldNameRef] === optionRef);
		const isExisting = holdingTemplate?.[loadKey].find(option => option[fieldNameRef] === optionRef);
		
		let nextState = holdingTemplate;
		if (isExisting) {
			nextState = { 
        ...holdingTemplate, 
        [loadKey]: holdingTemplate[loadKey].filter(option => option[fieldNameRef] !== optionRef)
      }
		} else {
			nextState = { 
        ...holdingTemplate, 
        [loadKey]: [...holdingTemplate[loadKey], selectedOption] 
      }
		}
		setHoldingTemplate(nextState)
	};

    const isValidHoldingTemplate = () => {
        const isThereSomeChecked = (
          holdingTemplate?.subjective?.length ||
          holdingTemplate?.objective?.length ||
          holdingTemplate?.assessment?.length ||
          holdingTemplate?.plan?.length
        )
        return holdingTemplate?.name && isThereSomeChecked && holdingTemplate?.specialty?.id
    }

    const handleSubmit = async () => {
        let payload = parseTemplate(holdingTemplate)
        
        dispatch(loading())
        try {
            await putSaveTemplateType(payload)
            await extraAction()
            dispatch(AddAlert('Template de Prontuário', 'Template de Prontuário salvo com sucesso!', AlertType.SUCCESS))
        } catch (err) {
            dispatch(AddAlert('Template de Prontuário', err?.response?.data?.error, AlertType.ERROR))
        }
        dispatch(loaded())
    }

    const handleOpenDeleteHoldingTemplate = () => {
        const extraActionDeleteHoldingTemplate = async () => {
            await extraAction()
            setModalInfoV3(MODAL_INIT_PROPS)
        }

        setModalInfoV3(prev => ({
            ...prev,
            open: true,
            title: 'Exclusão de Template',
            content: <DeleteHoldingTemplate 
                holdingTemplate={holdingTemplate}
                extraActionDeleteHoldingTemplate={extraActionDeleteHoldingTemplate}
                setSecondaryModal={setModalInfoV3}
            />
        }))
    }

    const handleSearchSpecialties = (event) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current)
        searchTimeout.current = setTimeout(() => {
            fetchSpecialtiesOptions({ name__icontains: event.target.value })
        }, 400, event.target.value)
    }

    // (NEW) 2. Reusable sections object
    const sections = {
      subjective: (
        <FieldsFor
          loadKey="subjective"
          holdingTemplate={holdingTemplate}
          setHoldingTemplate={setHoldingTemplate}
          toggleOption={toggleOption}
          moveItem={moveItem}
          ACTIVE_FIELD_OPTIONS={ACTIVE_FIELD_OPTIONS}
        />
      ),
      objective: (
        <FieldsFor
          loadKey="objective"
          holdingTemplate={holdingTemplate}
          setHoldingTemplate={setHoldingTemplate}
          toggleOption={toggleOption}
          moveItem={moveItem}
          ACTIVE_FIELD_OPTIONS={ACTIVE_FIELD_OPTIONS}
        />
      ),
      assessment: (
        <FieldsFor
          loadKey="assessment"
          holdingTemplate={holdingTemplate}
          setHoldingTemplate={setHoldingTemplate}
          toggleOption={toggleOption}
          moveItem={moveItem}
          ACTIVE_FIELD_OPTIONS={ACTIVE_FIELD_OPTIONS}
        />
      ),
      plan: (
        <FieldsFor
          loadKey="plan"
          holdingTemplate={holdingTemplate}
          setHoldingTemplate={setHoldingTemplate}
          toggleOption={toggleOption}
          moveItem={moveItem}
          ACTIVE_FIELD_OPTIONS={ACTIVE_FIELD_OPTIONS}
        />
      ),
    };

    return (
      <div className="NewEditHoldingTemplate">
        <Modal {...modalInfoV3} dismissFn={() => setModalInfoV3(MODAL_INIT_PROPS)} />
        
        <div className='NewEditHoldingTemplate-Body-HeaderInput'>
          <div className='NewEditHoldingTemplate-Input'>
            <span><b className='NewEditHoldingTemplate-Asterisk'>*</b> Nome</span>
            <Input
              label=''
              value={holdingTemplate.name}
              action={event => setHoldingTemplate(prev => ({ ...prev, 'name': event.target.value }))}
              maxLength={90}
            />
          </div>
          <div className='NewEditHoldingTemplate-Select'>
            <span><b className='NewEditHoldingTemplate-Asterisk'>*</b> Especialidade</span>
            <NewSelect
              label=''
              onSelect={event => {
                setHoldingTemplate(prev => ({ 
                  ...prev, 
                  "specialty": prev?.specialty?.id !== event.target.selected 
                      ? specialtiesOptions?.find(({ id }) => id === event.target.selected)
                      : ''
                }))
                fetchSpecialtiesOptions({ offset: 0 })
              }}     
              canBeEmpty={false}
              options={uniqueIDs(holdingTemplate?.specialty, specialtiesOptions)}
              optionRefKey='id'
              optionStrKey='strf'
              selected={holdingTemplate?.specialty?.id || ''}
              filterNode={
                <div className='NewEditHoldingTemplate-NewSelect'>
                  <Input
                    placeholder='Pesquisar por nome'
                    action={handleSearchSpecialties}
                    actionFocus={()=> fetchSpecialtiesOptions()}
                  />
                </div>
              }
            />
          </div>
        </div>

        <div className='NewEditHoldingTemplate-SectionsManager'>
          <div>
            <div
              className={`NewEditHoldingTemplate-SectionsManager-SideBarLeft-Btn ${openSection === 'subjective' ? 'active' : ''}`}
              onClick={() => setOpenSection('subjective')}
            >
              Subjetivo
            </div>
            <div
              className={`NewEditHoldingTemplate-SectionsManager-SideBarLeft-Btn ${openSection === 'objective' ? 'active' : ''}`}
              onClick={() => setOpenSection('objective')}
            >
              Objetivo
            </div>
            <div
              className={`NewEditHoldingTemplate-SectionsManager-SideBarLeft-Btn ${openSection === 'assessment' ? 'active' : ''}`}
              onClick={() => setOpenSection('assessment')}
            >
              Avaliação
            </div>
            <div
              className={`NewEditHoldingTemplate-SectionsManager-SideBarLeft-Btn ${openSection === 'plan' ? 'active' : ''}`}
              onClick={() => setOpenSection('plan')}
            >
              Plano
            </div>
          </div>
          <div className='NewEditHoldingTemplate-SectionsManager-Separator' />
          
          {/* (NEW) 3. Render the appropriate reusable section */}
          <div className='NewEditHoldingTemplate-SectionsManager-OpenSection'>
            { sections[openSection] }
          </div>
        </div>

        <div className='NewEditHoldingTemplate-BtnBox'>
          {holdingTemplate?.id && (
            <NewButton 
              label='Excluir Template'
              model={ButtonModel.WARNING_OUTLINED}
              onClick={handleOpenDeleteHoldingTemplate}
            />
          )}
          <NewButton
            label='Salvar Template'
            disabled={!isValidHoldingTemplate()}
            onClick={handleSubmit}
          />
        </div>
      </div>
    );
}
