import './CouncilRegisterPage.css'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal, { MODAL_INIT_PROPS } from '../../../newcomponents/modal/modal'
import CreateGenericCouncil from './subcomponents/CreateGenericCouncil/CreateGenericCouncil'
import { useApp } from '../../../layout/App'

export default function CouncilRegisterPage() {
    const dispatch = useDispatch()
    const [modalProps, setModalProps] = useState(MODAL_INIT_PROPS)
    const { logOut } = useApp()


    return (
        <div className='CouncilRegisterPage'>
        <Modal dismissText='Fechar' dismissFn={() => setModalProps(MODAL_INIT_PROPS)} {...modalProps} />
        <section>
            <h3>Salutho</h3>
            <div className='CouncilRegisterPage-Headline'>
                <h4>Conselho profissional</h4>
                <span>Preencha seu conselho profissional para concluirmos seu cadastro.</span>
            </div>

            <CreateGenericCouncil />

            <div className='CouncilRegisterPage-CancelRegister'>
                <span>Quer continuar mais tarde? <b onClick={() => logOut()}>Sair</b></span>
            </div>
        </section>

    </div>
    )
}